<template>
  <v-card>
    <v-card-text class="pa-0">
      <template>
        <v-toolbar card color="white">
          <v-text-field
            flat
            solo
            prepend-icon="search"
            placeholder="Filtrar"
            v-model="search"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
          <v-btn icon @click="cadastrar()">
            <v-icon>add_circle_outline</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="produto"
          :rows-per-page-items="[25, 50, 75, { text: 'All', value: -1 }]"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.referencia }}</td>
            <td>{{ props.item.nomeProduto }}</td>
            <td class="text-xs-right">{{ props.item.precoVenda }}</td>
            <td class="text-xs-right"><v-icon small>{{ props.item.semComissao ? 'check_circle' : 'close'}}</v-icon></td>
            <td class="text-xs-right">{{ props.item.quantidadeNasPracas }}</td>
            <td class="text-xs-right">{{ props.item.quantidadeDisponível }}</td>
            <td class="text-xs-right">{{ props.item.porcentagemVendasMesAnterior }}</td>
            <td class="text-xs-right">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" depressed icon dark v-bind="attrs" v-on="on">
                    <v-icon small>article</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-btn depressed icon dark color="primary" title="Editar" small @click="editar(props.item.id)">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn depressed icon dark color="primary" title="Visualizar" small @click="view(props.item.id)">
                    <v-icon small>remove_red_eye</v-icon>
                  </v-btn>
                  <v-btn depressed icon dark color="primary" title="Unificar" small @click="unificar(props.item.id)">
                    <v-icon small>compare_arrows</v-icon>
                  </v-btn>
                </v-list>
              </v-menu>
            </td>
          </template>
        </v-data-table>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import { ServicoProduto } from "../../servicos/servicoProduto"
const servicoProduto = new ServicoProduto()
export default {
  data() {
    return {
      search: "",
      produto: [],
      headers: [
        { text: "Id", align: "left", sortable: false, value: "id" },
        {
          text: "Referência",
          align: "left",
          sortable: false,
          value: "referencia"
        },
        { text: "Produto", align: "left", sortable: false, value: "nomeProduto" },
        { text: "R$ venda", align: "right", sortable: false, value: "precoVenda" },
        { text: "Sem Comissão", align: "right", sortable: false, value: "semComissao" },
        { text: "Qtd consignada", align: "right", sortable: false, value: "quantidadeNasPracas" },
        { text: "Qtd disponível", align: "right", sortable: false, value: "qQuantidadeDisponível" },
        { text: "% venda 30 dias", align: "right", sortable: false, value: "porcentagemVendasMesAnterior" },
        { text: "", value: "action", align: "right", sortable: false }
      ]
    }
  },
  created() {
    servicoProduto.buscarProdutosTable().then(
      res => {
        if (res.status === 200) {
          this.produto = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  },
  methods: {
    editar(id) {
      this.$router.push({ name: "edit-produto", params: { id: id } })
    },
    view(id) {
      this.$router.push({ name: "view-produto", params: { id: id } })
    },
    unificar(id) {
      this.$router.push({ name: "unificar-produto", params: { id: id } })
    },
    cadastrar() {
      this.$router.push({ name: "cadastro-produto" })
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
