<template>
  <div id="pageProduto">
    <v-container grid-list-xl fluid>
      <v-layout row wrap justify-center>
        <v-flex lg10 sm12 xs12>
          <produto-table></produto-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import ProdutoTable from "@/components/produto/tableProdutos"
export default {
  components: {
    ProdutoTable
  }
}
</script>
